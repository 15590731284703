.LoaderButton {
    width: 180px;
}
.LoaderButton .spinning {
    margin-right: 15px;
    transform: translateY(6px);
    /* animation: spin 1s infinite linear; */
}
/* @keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
} */